<template>
  <div class="mangerPage">
    <div class="main">
      <div class="top_view">
        <a-button type="primary" class="addBtn" @click="exportUserData"
          >导出</a-button
        >
        <div>
          <a-input-search
            class="search_view"
            v-model="searchValue"
            placeholder="请输入姓名或手机号查询"
            enter-button
            allow-clear
            @search="onSearchBanner"
          />
        </div>
      </div>
      <a-table
        :columns="columns"
        :data-source="tableData"
        bordered
        :scroll="{ x: 1400 }"
        :pagination="false"
        :rowKey="record => record.bannerId"
        :loading="tableLoad"
        class="table_container"
      >
        <template slot="action" slot-scope="text, record">
          <a-space>
            <a-button class="pointer" @click="goUrl(record.userId)">{{
              record.watchBoxNums
            }}</a-button>
          </a-space>
        </template>
        <template slot="fullName" slot-scope="text, record">
          {{ record.fullNameFlage ? record.fullNameInfo : record.fullName }}
          <div
            v-if="record.fullName.length > 0"
            class="mydiv"
            style="margin-left:90px;"
          >
            <a-icon
              :type="record.fullNameFlage ? 'eye-invisible' : 'eye'"
              @click="handleSwitch(record, 0)"
            />
          </div>
        </template>
        <template slot="phone" slot-scope="text, record">
          {{ record.phoneFlage ? record.phoneInfo : record.phone }}
          <div
            v-if="record.phone.length > 0"
            class="mydiv"
            style="margin-left:90px;"
          >
            <a-icon
              :type="record.phoneFlage ? 'eye-invisible' : 'eye'"
              @click="handleSwitch(record, 1)"
            />
          </div>
        </template>
      </a-table>
      <div class="page_warp">
        <a-pagination
          class="pagination"
          v-model="page.current"
          :total="page.total"
          show-less-items
          :defaultPageSize="page.size"
          @change="pageCurrentChange"
        />
      </div>
    </div>
  </div>
</template>
<script>
import API from "@/request";
import moment from "moment";
import exportExcelMethod from "@/request/http";
export default {
  data() {
    return {
      ...this.data()
    };
  },
  methods: {
    data() {
      return {
        loading: false, // 封面图上传加载效果控制字段
        tableLoad: false, // 表格数据加载效果
        page: {
          // 表格分页相关
          total: 0,
          current: 1,
          size: 10,
          showTotal: total => `共有 ${total} 条数据` //分页中显示总的数据
        },
        tableData: [], // 表格数据
        changStatus: "", // 表格右上类型筛选绑定字段
        searchValue: "",
        statusValue: "",
        typeValue: 0,
        columns: [
          {
            title: "姓名",
            dataIndex: "fullName",
            scopedSlots: { customRender: "fullName" }
          },
          {
            title: "称谓",
            dataIndex: "nameIdentity"
          },
          {
            title: "手机号",
            dataIndex: "phone",
            scopedSlots: { customRender: "phone" }
          },
          {
            title: "省/市/区",
            dataIndex: "locationInfo"
          },
          {
            title: "详细地址",
            dataIndex: "addressDetail"
          },
          {
            title: "生日",
            dataIndex: "birthday"
          },
          {
            title: "邮箱地址",
            dataIndex: "email"
          },
          {
            title: "意愿接触渠道",
            dataIndex: "contactChannels"
          },
          {
            title: "兴趣爱好",
            dataIndex: "hobby"
          },
          {
            title: "是否允许跨境转移个人信息",
            dataIndex: "canUsingPersonalInfo"
          },
          {
            title: "watch box",
            dataIndex: "watchBoxNums",
            scopedSlots: { customRender: "action" }
          }
        ],
        isAdd: false, // 控制新增表单弹窗控制字段
        header_title: "新增",
        isShowPriview: false
      };
    },
    //切换脱敏显示
    handleSwitch(result, type) {
      if (result) {
        if (type === 0) {
          result.fullNameFlage = !result.fullNameFlage;
          result.fullNameInfo =
            result.fullName.substr(0, 1) +
            new Array(result.fullName.length).join("*");
        } else {
          result.phoneFlage = !result.phoneFlage;
          result.phoneInfo = result.phone.replace(
            /(\d{3})(\d{4})(\d{4})/,
            "$1****$3"
          );
        }
        for (const val of this.tableData) {
          if (val.userId === result.userId) {
            this.$set(this.tableData, val, result);
          }
        }
      }
    },
    resetData() {
      Object.assign(this.$data, this.data());
    },
    pageCurrentChange(val) {
      // 分页页码变化
      this.page.current = val;
      this.getList();
    },
    goUrl(userId) {
      // this.$router.replace('/bannerLevel')
      this.$router.push({ path: "/watchBox", query: { userId: userId } });
    },
    //状态搜索
    changStatusValue(value) {
      this.statusValue = value;
      this.getList();
    },
    dateFormat(val) {
      return moment(val).format("YYYY年MM月DD日 a HH");
    },
    /**
     * 导出用户
     */
    async exportUserData() {
      if (this.page.total > 0) {
        let exObj = {
          methods: "GET",
          url:
            process.env.VUE_APP_BASE_API +
            "/user/exportExcel?keyword=" +
            this.searchValue,
          fileName: "真力时延保小程序注册用户信息"
        };
        exportExcelMethod.exportExcelMethod(exObj);
        // window.location.href = process.env.VUE_APP_BASE_API+'/user/exportExcel?keyword='+this.searchValue;
      } else {
        this.$message.success("无数据可导出");
      }
    },
    //名称搜索 轮播图
    onSearchBanner(value) {
      this.getList();
    },
    // 获取表格数据
    async getList(
      current = this.page.current,
      size = this.page.size,
      keyword = this.searchValue
    ) {
      this.tableLoad = true;
      let res = await API.getUserList({ current, keyword, size });
      if (res.data.code === "0000") {
        let data = res.data.data;
        data.records.forEach(element => {
          element.fullNameFlage = true;
          element.phoneFlage = true;
          element.fullNameInfo =
            element.fullName &&
            element.fullName.substr(0, 1) +
              new Array(element.fullName.length).join("*");
          element.phoneInfo =
            element.phone &&
            element.phone.replace(/(\d{3})(\d{4})(\d{4})/, "$1****$3");
        });
        this.tableData = data.records;
        this.tableLoad = false;
        this.page = {
          // 设置分页信息
          current: data.current,
          total: data.total,
          size: data.size
        };
      }
    }
  },
  mounted() {
    this.getList(); // 请求表格数据
  }
};
</script>

<style lang="less" scoped>
/deep/.has-error .ant-upload.ant-upload-select-picture-card {
  border: 1px dashed #f5222d !important;
}

.main {
  margin: 24px;
  background: #ffffff;
  padding: 24px;
}
.search_View {
  width: 230px;
}
.top_view {
  display: flex;
  justify-content: space-between;
  height: 48px;
}
.search_view {
  width: 280px;
  margin-right: 20px;
}
.page_warp {
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
}

/deep/ .table_container .ant-table-row td {
  position: relative !important;
}
.mydiv {
  position: absolute;
  bottom: 0;
}
</style>
